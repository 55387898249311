import { matchPath } from 'react-router-dom'

import { UBREACH_V2_ENABLED } from './environment'

import { hasAllPermissions, hasAnyPermission } from '../helpers/hasPermission'
import { permissions } from './permissions'

// This is only needed before uBreach Pro's full launch to avoid premature access to the upgrade process
const DISABLE_UBREACH_PRO_UPGRADE = window.__USECURE_CONFIG__.REACT_APP_DISABLE_UBREACH_PRO_UPGRADE === 'true'

export const uBreachAllowed = ({ settings }) => settings.uBreachEnabled === true
export const uBreachV2Allowed = state => UBREACH_V2_ENABLED && uBreachAllowed(state)
export const uBreachProUpgradeAllowed = (state) => (
  !DISABLE_UBREACH_PRO_UPGRADE &&
  uBreachAllowed(state) && (
    // Direct MSP or Disti MSP where UBreach Pro is enabled on the distributor
    (state.session.accountType === 'msp' && (!state.session.distributorId || state.session.distributorUBreachProEnabled)) ||
    // Direct, paid Tenant
    (state.session.accountType === 'tenant' && !state.session.mspId && state.session.planName !== 'freeTrial')
  )
)
export const uLearnAllowed = ({ settings }) => settings.uLearn === true
export const uPhishAllowed = ({ settings }) => settings.uPhish === true
export const uPolicyAllowed = ({ settings }) => settings.uPolicy === true
export const uServiceAllowed = ({ session, settings }) => ((session.partner || hasAllPermissions({ availablePermisisons: session.permissions, requiredPermissions: [permissions.COMPANY_SUPER_LIST] })) && !uServiceMSPAllowed({ session, settings }))
export const uServiceMSPAllowed = ({ session, settings }) => session.accountType === 'msp' && settings.enableProspects === true
export const uServiceProspectCreationAllowed = ({ session, settings }) => uServiceMSPAllowed({ session, settings }) && settings.disableProspectCreation !== true
export const uServiceInvoiceAllowed = ({ session, settings }) =>
  (session.accountType === 'msp' && (session.billingType === 'auto' || (session.distributorId && settings.distiMSPInvoicesEnabled === true))) ||
  session.accountType === 'distributor'
export const paymentAllowed = ({ session }) => session.accountType === 'msp' && session.billingType === 'auto'
export const distributorBillingAllowed = ({ session }) => session.accountType === 'distributor' && session.billingType === 'manual'
export const uPolicyTemplateBuilderAllowed = ({ session, settings }) =>
  uPolicyAllowed({ session, settings }) &&
  (hasAnyPermission({ availablePermisisons: session.permissions, requiredPermissions: [permissions.POLICY_TEMPLATE_SUPER_LIST] }) || session.partner)
export const isMSP = ({ session }) => session.accountType === 'msp'
export const reportCentreAllowed = ({ session, settings }) => session.accountType === 'tenant' || session.accountType === 'msp' || (session.accountType === 'distributor' && settings.platformAccess === true)
export const uBreachProReportAllowed = ({ session, settings }) => reportCentreAllowed({ session, settings }) && settings.uBreachPro === true

export const routes = [
  { id: 'HOME', path: '/', groups: ['MAIN', 'PRE_SIGN_IN'], anyPermissions: [permissions.BREACH_REPORT, permissions.COURSE_REPORT, permissions.POLICY_REPORT, permissions.SIMULATION_REPORT] },
  { id: 'REGISTER', path: '/register', groups: ['PRE_SIGN_IN'] },
  { id: 'RESET_PASSWORD', path: '/password-reset/:token', groups: ['PRE_SIGN_IN'] },
  { id: 'SET_PASSWORD', path: '/invite/:token', groups: ['PRE_SIGN_IN'] },
  { id: 'VERIFY_EMAIL', path: '/verify/:token', groups: ['PRE_SIGN_IN'] },
  { id: 'FORGOTTEN_PASSWORD', path: '/forgotten-password', groups: ['PRE_SIGN_IN'] },
  {
    id: 'UBREACH',
    path: '/breach',
    groups: ['MAIN'],
    allowed: uBreachAllowed,
    prospectStatus: 'ubreach_started',
    allPermissions: [permissions.BREACH_LIST, permissions.BREACH_READ, permissions.BREACH_RESOLUTION_READ, permissions.BREACH_RESOLUTION_LIST]
  },
  { id: 'UBREACH_UPGRADE', path: '/breach/upgrade', groups: ['MAIN'], allowed: uBreachProUpgradeAllowed, allPermissions: [permissions.BILLING_UPDATE] },
  { id: 'UPOLICY', path: '/policy/policies', groups: ['MAIN'], allowed: uPolicyAllowed, allPermissions: [permissions.POLICY_LIST, permissions.POLICY_READ] },
  { id: 'UPOLICY_CREATE', path: '/policy/create', groups: ['MAIN'], allowed: uPolicyAllowed, allPermissions: [permissions.POLICY_CREATE] },
  { id: 'UPOLICY_CREATE_FROM_TEMPLATE', path: '/policy/create-from-template', groups: ['MAIN'], allowed: uPolicyAllowed, allPermissions: [permissions.POLICY_CREATE] },
  { id: 'UPOLICY_EDIT', path: '/policy/edit/:policyId', groups: ['MAIN'], allowed: uPolicyAllowed, allPermissions: [permissions.POLICY_UPDATE] },
  { id: 'UPOLICY_EDIT_DRAFT', path: '/policy/edit/:policyId/draft', groups: ['MAIN'], allowed: uPolicyAllowed, allPermissions: [permissions.POLICY_UPDATE] },
  { id: 'UPOLICY_VIEW', path: '/policy/view/:policyId', groups: ['MAIN'], allowed: uPolicyAllowed, allPermissions: [permissions.POLICY_READ] },
  { id: 'UPOLICY_TEMPLATES', path: '/policy/templates', groups: ['MAIN'], allowed: uPolicyAllowed, allPermissions: [permissions.POLICY_TEMPLATE_LIST] },
  { id: 'UPOLICY_TEMPLATE_BUILDER', path: '/policy/templates/builder', groups: ['MAIN'], allowed: uPolicyTemplateBuilderAllowed, anyPermissions: [permissions.POLICY_TEMPLATE_CREATE, permissions.POLICY_TEMPLATE_UPDATE, permissions.POLICY_TEMPLATE_DELETE] },
  { id: 'UPOLICY_TEMPLATE_CREATE', path: '/policy/templates/create', groups: ['MAIN'], allowed: uPolicyTemplateBuilderAllowed, allPermissions: [permissions.POLICY_TEMPLATE_CREATE] },
  { id: 'UPOLICY_TEMPLATE_EDIT', path: '/policy/templates/edit/:policyId', groups: ['MAIN'], allowed: uPolicyTemplateBuilderAllowed, allPermissions: [permissions.POLICY_TEMPLATE_UPDATE] },
  { id: 'UPOLICY_TEMPLATE_EDIT_DRAFT', path: '/policy/templates/edit/:policyId/draft', groups: ['MAIN'], allowed: uPolicyTemplateBuilderAllowed, allPermissions: [permissions.POLICY_TEMPLATE_UPDATE] },
  { id: 'UPOLICY_TEMPLATE_VIEW', path: '/policy/templates/view/:policyId', groups: ['MAIN'], allowed: uPolicyTemplateBuilderAllowed, allPermissions: [permissions.POLICY_TEMPLATE_READ] },
  { id: 'UPOLICY_TEMPLATE_BUILDER_VIEW', path: '/policy/templates/builder/view/:policyId', groups: ['MAIN'], allowed: uPolicyTemplateBuilderAllowed, anyPermissions: [permissions.POLICY_TEMPLATE_CREATE, permissions.POLICY_TEMPLATE_UPDATE, permissions.POLICY_TEMPLATE_DELETE] },
  {
    id: 'USERVICE',
    path: '/uservice',
    allowed: uServiceAllowed,
    groups: ['MAIN'],
    platformAccess: false,
    allPermissions: [permissions.COMPANY_READ, permissions.COMPANY_LIST]
  },
  {
    id: 'USERVICE_MSP_TYPE',
    path: '/uservice/:accountType(customers|prospects)',
    allowed: uServiceMSPAllowed,
    groups: ['MAIN'],
    platformAccess: false,
    allPermissions: [permissions.COMPANY_LIST, permissions.COMPANY_READ]
  },
  {
    id: 'USERVICE_MSP_TYPE_CREATE',
    path: '/uservice/:accountType(customers|prospects)/create',
    allowed: uServiceProspectCreationAllowed,
    groups: ['MAIN'],
    platformAccess: false,
    allPermissions: [permissions.COMPANY_CREATE]
  },
  {
    id: 'USERVICE_RISK_REPORT_SIGNUP',
    path: '/uservice/risk-report-sign-up',
    allowed: uServiceProspectCreationAllowed,
    groups: ['MAIN'],
    platformAccess: false,
    allPermissions: [permissions.SETTINGS_RISK_REPORT_READ]
  },
  {
    id: 'USERVICE_INVOICE',
    path: '/uservice/invoice',
    allowed: uServiceInvoiceAllowed,
    groups: ['MAIN'],
    platformAccess: false,
    allPermissions: [permissions.BILLING_READ, permissions.COMPANY_LIST]
  },
  {
    id: 'USERVICE_INVOICE_DISTRIBUTOR',
    path: '/uservice/distributor/invoice',
    allowed: uServiceInvoiceAllowed,
    groups: ['MAIN'],
    platformAccess: false,
    allPermissions: [permissions.BILLING_READ, permissions.COMPANY_LIST]
  },
  { id: 'LEARNERS', path: '/learners', groups: ['MAIN'], prospectStatus: 'ubreach_requested', allPermissions: [permissions.LEARNER_READ, permissions.LEARNER_LIST] },
  { id: 'LEARNER', path: '/learners/view/:learnerId', groups: ['MAIN'], prospectStatus: 'ubreach_requested', allPermissions: [permissions.COURSE_ENROLMENT_LIST] },
  { id: 'LEARNER_OLD', path: '/learner/:learnerId', groups: ['MAIN'], prospectStatus: 'ubreach_requested', allPermissions: [permissions.LEARNER_READ] },
  { id: 'UPHISH_SIMS', path: '/phish/simulations', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.SIMULATION_READ, permissions.SIMULATION_LIST] },
  { id: 'UPHISH_CREATE_SIM', path: '/phish/create', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.SIMULATION_CREATE] },
  { id: 'UPHISH_CREATE_SIM_FROM_REC', path: '/phish/create-from-rec/:simulation_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.SIMULATION_CREATE] },
  { id: 'UPHISH_CLONE_SIM', path: '/phish/clone/:simulation_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.SIMULATION_CREATE] },
  { id: 'UPHISH_EDIT_SIM', path: '/phish/edit/:simulation_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.SIMULATION_UPDATE] },
  { id: 'UPHISH_SIM', path: '/phish/view/:simulation_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.SIMULATION_READ] },
  { id: 'UPHISH_SIM_USERS', path: '/phish/view/:simulation_id/users', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.SIMULATION_READ, permissions.LEARNER_LIST] },
  { id: 'UPHISH_AUTO_PHISH', path: '/phish/auto-phish', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.SETTINGS_UPHISH_READ] },
  { id: 'UPHISH_LANDING_PAGES', path: '/phish/landing-pages', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.LANDING_PAGE_READ, permissions.LANDING_PAGE_LIST] },
  { id: 'UPHISH_LANDING_PAGE_BUILDER', path: '/phish/landing-pages/builder', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.LANDING_PAGE_READ, permissions.LANDING_PAGE_LIST], anyPermissions: [permissions.LANDING_PAGE_CREATE, permissions.LANDING_PAGE_UPDATE, permissions.LANDING_PAGE_DELETE] },
  { id: 'UPHISH_LANDING_PAGE_CREATE', path: '/phish/landing-pages/create', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.LANDING_PAGE_CREATE] },
  { id: 'UPHISH_LANDING_PAGE_UPDATE', path: '/phish/landing-pages/edit/:landing_page_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.LANDING_PAGE_UPDATE] },
  { id: 'UPHISH_LANDING_PAGE_CLONE', path: '/phish/landing-pages/clone/:landing_page_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.LANDING_PAGE_CREATE] },
  { id: 'UPHISH_LANDING_PAGE_VIEW', path: '/phish/landing-pages/view/:landing_page_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.LANDING_PAGE_READ] },
  { id: 'UPHISH_LANDING_PAGE_BUILDER_VIEW', path: '/phish/landing-pages/builder/view/:landing_page_id', groups: ['MAIN'], allowed: uPhishAllowed, allPermissions: [permissions.LANDING_PAGE_READ] },
  { id: 'UPHISH_EMAIL_TEMPLATES', path: '/phish/email-templates', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.EMAIL_TEMPLATE_READ, permissions.EMAIL_TEMPLATE_LIST] },
  { id: 'UPHISH_EMAIL_TEMPLATE_BUILDER', path: '/phish/email-templates/builder', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.EMAIL_TEMPLATE_READ, permissions.EMAIL_TEMPLATE_LIST], anyPermissions: [permissions.EMAIL_TEMPLATE_CREATE, permissions.EMAIL_TEMPLATE_UPDATE, permissions.EMAIL_TEMPLATE_DELETE] },
  { id: 'UPHISH_EMAIL_TEMPLATE_CREATE', path: '/phish/email-templates/create', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.EMAIL_TEMPLATE_CREATE] },
  { id: 'UPHISH_EMAIL_TEMPLATE_UPDATE', path: '/phish/email-templates/edit/:email_template_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.EMAIL_TEMPLATE_UPDATE] },
  { id: 'UPHISH_EMAIL_TEMPLATE_CLONE', path: '/phish/email-templates/clone/:email_template_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.EMAIL_TEMPLATE_CREATE] },
  { id: 'UPHISH_EMAIL_TEMPLATE_VIEW', path: '/phish/email-templates/view/:email_template_id', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.EMAIL_TEMPLATE_READ] },
  { id: 'UPHISH_EMAIL_TEMPLATE_BUILDER_VIEW', path: '/phish/email-templates/builder/view/:email_template_id', groups: ['MAIN'], allowed: uPhishAllowed, allPermissions: [permissions.EMAIL_TEMPLATE_READ] },
  {
    id: 'COURSE_LIBRARY',
    path: '/learn/course-library/',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_LIST]
  },
  {
    id: 'ULEARN_REPORT',
    path: '/learn/courses-report/',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_REPORT]
  },
  {
    id: 'LEARNER_REPORT',
    path: '/learn/courses-report/learner/:learnerId',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_ENROLMENT_LIST]
  },
  {
    id: 'COURSE_REPORT',
    path: '/learn/courses-report/course/:courseId',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_ENROLMENT_LIST]
  },
  {
    id: 'GAP_ANALYSIS_REPORT',
    path: '/learn/gap-analysis-report',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_REPORT]
  },
  {
    id: 'COURSE_LIBRARY_PREVIEW',
    path: '/learn/course-library/preview/:course_id',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_READ]
  },
  {
    id: 'BUILDER',
    path: '/learn/builder/',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_LIST],
    anyPermissions: [permissions.COURSE_CREATE, permissions.COURSE_UPDATE, permissions.COURSE_DELETE]
  },
  {
    id: 'BUILDER_CREATE',
    path: '/learn/builder/create',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_CREATE]
  },
  {
    id: 'BUILDER_EDIT',
    path: '/learn/builder/edit/:course_id',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_UPDATE]
  },
  {
    id: 'BUILDER_PREVIEW_COURSE',
    path: '/learn/builder/preview/:course_id',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_READ]
  },
  {
    id: 'BUILDER_PREVIEW_COURSE_LOCALE',
    path: '/learn/builder/preview/:course_id/locale/:locale',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_READ]
  },
  {
    id: 'BUILDER_PREVIEW_SLIDE',
    path: '/learn/builder/preview/:course_id/slide/:slide_id',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_READ]
  },
  {
    id: 'BUILDER_PREVIEW_SLIDE_LOCALE',
    path: '/learn/builder/preview/:course_id/slide/:slide_id/:locale',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_READ]
  },
  {
    id: 'BUILDER_EDIT_SLIDES',
    path: '/learn/builder/edit/:course_id/slides',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_UPDATE]
  },
  {
    id: 'BUILDER_EDIT_SLIDES_LOCALE',
    path: '/learn/builder/edit/:course_id/slides/:locale',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_UPDATE]
  },
  {
    id: 'BUILDER_EDIT_SLIDE',
    path: '/learn/builder/edit/:course_id/slide/:slide_id',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_UPDATE]
  },
  {
    id: 'BUILDER_EDIT_SLIDE_LOCALE',
    path: '/learn/builder/edit/:course_id/slide/:slide_id/:locale',
    groups: ['MAIN'],
    allowed: uLearnAllowed,
    allPermissions: [permissions.COURSE_UPDATE]
  },
  { id: 'COURSE_PARTICIPATION', path: '/reports/course-participation', groups: ['MAIN'], allowed: uLearnAllowed, allPermissions: [permissions.COURSE_REPORT] },
  { id: 'SIMULATION_PERFORMANCE', path: '/reports/simulations', groups: ['MAIN'], allowed: uPhishAllowed, allPermissions: [permissions.SIMULATION_REPORT] },
  { id: 'LEARNER_COURSE', path: '/learner/:learnerId/course/:courseId' },
  { id: 'EXTERNAL_POLICIES', path: '/external/:key/policies' },
  { id: 'EXTERNAL_POLICY', path: '/external/:key/policy/:policyId' },
  { id: 'LEARNER_POLICY', path: '/learner/:learnerId/policy/:policyId' },
  { id: 'RISK_REPORT', path: '/risk-report/:companyId' },
  { id: 'RISK_REPORT_PREVIEW', path: '/risk-report-preview', groups: ['MAIN'], prospectStatus: 'new' },
  { id: 'SETTINGS', path: '/settings', groups: ['MAIN'], platformAccess: false, prospectStatus: 'new', anyPermissions: [permissions.SETTINGS_UBREACH_READ, permissions.SETTINGS_EMAIL_READ, permissions.SETTINGS_GENERAL_READ, permissions.SETTINGS_ULEARN_READ, permissions.SETTINGS_ULEARN_READ, permissions.SETTINGS_UPHISH_READ, permissions.SETTINGS_UPOLICY_READ] },
  { id: 'SETTINGS_SCREEN', path: '/settings/:screenPath+', groups: ['MAIN'], platformAccess: false, prospectStatus: 'new', anyPermissions: [permissions.SETTINGS_UBREACH_READ, permissions.SETTINGS_EMAIL_READ, permissions.SETTINGS_GENERAL_READ, permissions.SETTINGS_ULEARN_READ, permissions.SETTINGS_ULEARN_READ, permissions.SETTINGS_UPHISH_READ, permissions.SETTINGS_UPOLICY_READ] },
  { id: 'DEFAULT_CUSTOMER_SETTINGS', path: '/default-customer-settings', groups: ['MAIN'], platformAccess: false, allowed: isMSP, allPermissions: [permissions.DEFAULT_TENANT_SETTINGS_READ] },
  { id: 'DEFAULT_CUSTOMER_SETTINGS_SCREEN', path: '/default-customer-settings/:screenPath+', groups: ['MAIN'], platformAccess: false, allowed: isMSP, allPermissions: [permissions.DEFAULT_TENANT_SETTINGS_READ] },
  { id: 'TEAM', path: '/settings/team', groups: ['MAIN'], platformAccess: false, allPermissions: [permissions.USER_LIST] },
  { id: 'ACCOUNT', path: '/account', groups: ['MAIN'], platformAccess: false },
  { id: 'PAYMENT', path: '/account/payment', groups: ['MAIN'], platformAccess: false, allowed: paymentAllowed, allPermissions: [permissions.BILLING_READ] },
  { id: 'HOLDING', path: '/holding', groups: ['MAIN'], platformAccess: false },
  { id: 'OFFICE_365_SETUP', path: '/office-365-setup', groups: ['MAIN'], prospectStatus: 'ubreach_requested', allPermissions: [permissions.SETTINGS_SYNC_READ] },
  { id: 'PHISH_ALERT_M365_AUTH', path: '/phish-alert-m365-auth', groups: ['MAIN'], allowed: uPhishAllowed, allPermissions: [permissions.SETTINGS_UPHISH_READ] },
  { id: 'MESSAGE_INJECTION_M365_AUTH', path: '/message-injection-m365-auth', groups: ['MAIN'], allowed: uPhishAllowed, prospectStatus: 'uphish_requested', allPermissions: [permissions.SETTINGS_UPHISH_READ] },
  { id: 'M365_AUTH_TEST_START', path: '/ms-auth-test-start', groups: ['MAIN'], allPermissions: [permissions.SETTINGS_SYNC_UPDATE] },
  { id: 'M365_AUTH_TEST', path: '/ms-auth-test', groups: ['MAIN'], allPermissions: [permissions.SETTINGS_SYNC_UPDATE] },
  { id: 'GOOGLE_AUTH_TEST_START', path: '/google-auth-test-start', groups: ['MAIN'], allPermissions: [permissions.SETTINGS_SYNC_UPDATE] },
  { id: 'GOOGLE_AUTH_TEST', path: '/google-auth-test', groups: ['MAIN'], allPermissions: [permissions.SETTINGS_SYNC_UPDATE] },
  { id: 'GOOGLE_SYNC_SETUP', path: '/google-sync-setup', groups: ['MAIN'], prospectStatus: 'ubreach_requested', allPermissions: [permissions.SETTINGS_SYNC_UPDATE] },
  { id: 'PORTAL_HOME', path: '/portal/:companyId', groups: ['PORTAL', 'PORTAL_MAIN'], allPermissions: [permissions.END_USER_ACCESS_FULL] },
  { id: 'PORTAL_MAGIC_REDIRECT', path: '/portal/:companyId/magicLink/:token', groups: ['PORTAL'] },
  {
    id: 'PORTAL_LEARN',
    path: '/portal/:companyId/learn',
    groups: ['PORTAL', 'PORTAL_MAIN'],
    allPermissions: [permissions.END_USER_ACCESS_FULL],
    allowed: uLearnAllowed
  },
  {
    id: 'PORTAL_POLICY',
    path: '/portal/:companyId/policy',
    groups: ['PORTAL', 'PORTAL_MAIN'],
    allPermissions: [permissions.END_USER_ACCESS_FULL],
    allowed: uPolicyAllowed
  },
  {
    id: 'PORTAL_BREACH',
    path: '/portal/:companyId/breach',
    groups: ['PORTAL', 'PORTAL_MAIN'],
    allPermissions: [permissions.END_USER_ACCESS_FULL],
    allowed: uBreachV2Allowed
  },
  { id: 'PORTAL_SETTINGS', path: '/portal/:companyId/settings', groups: ['PORTAL', 'PORTAL_MAIN'], allPermissions: [permissions.END_USER_ACCESS_FULL] },
  { id: 'OUTSTANDING_ACTIVITIES_LOGIN', path: '/company/:companyId/', groups: ['PORTAL'] },
  { id: 'OUTSTANDING_ACTIVITIES', path: '/company/:companyId/outstanding-activity', groups: ['PORTAL'], allPermissions: [permissions.END_USER_ACCESS_LIMITED] },
  { id: 'PERFORMANCE_REPORT', path: '/performance-report', groups: ['MAIN'], allPermissions: [permissions.PERFORMANCE_REPORT_LIST], allowed: reportCentreAllowed, platformAccess: false },
  { id: 'BREACH_REPORT', path: '/breach-report', groups: ['MAIN'], allPermissions: [permissions.BREACH_REPORT_LIST], allowed: uBreachProReportAllowed, platformAccess: false },
  { id: 'REPORT_CENTRE', path: '/reports/home', groups: ['MAIN'], anyPermissions: [permissions.BREACH_REPORT_LIST, permissions.PERFORMANCE_REPORT_LIST, permissions.SCHEDULED_REPORT_LIST], allowed: reportCentreAllowed, platformAccess: false },
  { id: 'PERFORMANCE_REPORT_CONTENT_VIEW', path: '/access/reports/performanceReport/view/:token', allPermissions: [permissions.REPORT_ACCESS] },
  { id: 'BREACH_REPORT_CONTENT_VIEW', path: '/access/reports/breachReport/view/:token', allPermissions: [permissions.REPORT_ACCESS] },
  { id: 'DOMAIN_SCAN_REPORT_CONTENT_VIEW', path: '/access/reports/domainScanReport/view/:token', allPermissions: [permissions.REPORT_ACCESS] },
  { id: 'DOWNLOAD_REPORT', path: '/access/reports/:reportType/download/:token', allPermissions: [permissions.REPORT_ACCESS] },
  { id: 'SAML_AUTH_TEST', path: '/sso-saml-login-test/:authType(admin-user|end-user|no-auth-type)/:status(start|success|fail|mfa)', groups: ['PRE_SIGN_IN'] },
  { id: 'SAML_AUTH_FAIL', path: '/sso/saml/fail', groups: ['PRE_SIGN_IN'] },
  { id: 'MFA_LOGIN', path: '/mfa', groups: ['PRE_SIGN_IN'] },
  { id: 'SAML_ADMIN_USER_AUTH_RESPONSE', path: '/sso/saml/admin-user/:status(success|fail)', groups: ['PRE_SIGN_IN'] },
  { id: 'SAML_END_USER_AUTH_RESPONSE', path: '/sso/saml/end-user/:status(success|fail)', groups: ['PORTAL'] }
]

export const routesIdMap = routes.reduce((map, route) => ({ ...map, [route.id]: route }), {})
export const routesPathMap = routes.reduce((map, route) => ({ ...map, [route.path]: route }), {})
const routesIdToPathMap = routes.reduce((map, route) => ({ ...map, [route.id]: route.path }), {})

export const redirects = [
  { from: '/ubreach', to: 'UBREACH' },
  { from: '/upolicy/policies', to: 'UPOLICY' },
  { from: '/upolicy/create', to: 'UPOLICY_CREATE' },
  { from: '/upolicy/create-from-template', to: 'UPOLICY_CREATE_FROM_TEMPLATE' },
  { from: '/upolicy/edit/:policyId', to: 'UPOLICY_EDIT' },
  { from: '/upolicy/edit/:policyId/draft', to: 'UPOLICY_EDIT_DRAFT' },
  { from: '/upolicy/view/:policyId', to: 'UPOLICY_VIEW' },
  { from: '/upolicy/templates', to: 'UPOLICY_TEMPLATES' },
  { from: '/upolicy/templates/builder', to: 'UPOLICY_TEMPLATE_BUILDER' },
  { from: '/upolicy/templates/create', to: 'UPOLICY_TEMPLATE_CREATE' },
  { from: '/upolicy/templates/edit/:policyId', to: 'UPOLICY_TEMPLATE_EDIT' },
  { from: '/upolicy/templates/edit/:policyId/draft', to: 'UPOLICY_TEMPLATE_EDIT_DRAFT' },
  { from: '/upolicy/templates/view/:policyId', to: 'UPOLICY_TEMPLATE_VIEW' },
  { from: '/upolicy/templates/builder/view/:policyId', to: 'UPOLICY_TEMPLATE_BUILDER_VIEW' },
  { from: '/uphish/simulations', to: 'UPHISH_SIMS' },
  { from: '/uphish/create', to: 'UPHISH_CREATE_SIM' },
  { from: '/uphish/create-from-rec/:simulation_id', to: 'UPHISH_CREATE_SIM_FROM_REC' },
  { from: '/uphish/clone/:simulation_id', to: 'UPHISH_CLONE_SIM' },
  { from: '/uphish/edit/:simulation_id', to: 'UPHISH_EDIT_SIM' },
  { from: '/uphish/view/:simulation_id', to: 'UPHISH_SIM' },
  { from: '/uphish/view/:simulation_id/users', to: 'UPHISH_SIM_USERS' },
  { from: '/uphish/auto-phish', to: 'UPHISH_AUTO_PHISH' },
  { from: '/uphish/landing-pages', to: 'UPHISH_LANDING_PAGES' },
  { from: '/uphish/landing-pages/builder', to: 'UPHISH_LANDING_PAGE_BUILDER' },
  { from: '/uphish/landing-pages/create', to: 'UPHISH_LANDING_PAGE_CREATE' },
  { from: '/uphish/landing-pages/edit/:landing_page_id', to: 'UPHISH_LANDING_PAGE_UPDATE' },
  { from: '/uphish/landing-pages/clone/:landing_page_id', to: 'UPHISH_LANDING_PAGE_CLONE' },
  { from: '/uphish/landing-pages/view/:landing_page_id', to: 'UPHISH_LANDING_PAGE_VIEW' },
  { from: '/uphish/landing-pages/builder/view/:landing_page_id', to: 'UPHISH_LANDING_PAGE_BUILDER_VIEW' },
  { from: '/uphish/email-templates', to: 'UPHISH_EMAIL_TEMPLATES' },
  { from: '/uphish/email-templates/builder', to: 'UPHISH_EMAIL_TEMPLATE_BUILDER' },
  { from: '/uphish/email-templates/create', to: 'UPHISH_EMAIL_TEMPLATE_CREATE' },
  { from: '/uphish/email-templates/edit/:email_template_id', to: 'UPHISH_EMAIL_TEMPLATE_UPDATE' },
  { from: '/uphish/email-templates/clone/:email_template_id', to: 'UPHISH_EMAIL_TEMPLATE_CLONE' },
  { from: '/uphish/email-templates/view/:email_template_id', to: 'UPHISH_EMAIL_TEMPLATE_VIEW' },
  { from: '/uphish/email-templates/builder/view/:email_template_id', to: 'UPHISH_EMAIL_TEMPLATE_BUILDER_VIEW' },
  { from: '/course-library/', to: 'COURSE_LIBRARY' },
  { from: '/ulearn-report/', to: 'ULEARN_REPORT' },
  { from: '/ulearn-report/learner/:learnerId', to: 'LEARNER_REPORT' },
  { from: '/ulearn-report/course/:courseId', to: 'COURSE_REPORT' },
  { from: '/gap-analysis-report', to: 'GAP_ANALYSIS_REPORT' },
  { from: '/course-library/preview/:course_id', to: 'COURSE_LIBRARY_PREVIEW' },
  { from: '/builder/', to: 'BUILDER' },
  { from: '/builder/create', to: 'BUILDER_CREATE' },
  { from: '/builder/edit/:course_id', to: 'BUILDER_EDIT' },
  { from: '/builder/preview/:course_id', to: 'BUILDER_PREVIEW_COURSE' },
  { from: '/builder/preview/:course_id/locale/:locale', to: 'BUILDER_PREVIEW_COURSE_LOCALE' },
  { from: '/builder/preview/:course_id/slide/:slide_id', to: 'BUILDER_PREVIEW_SLIDE' },
  { from: '/builder/preview/:course_id/slide/:slide_id/:locale', to: 'BUILDER_PREVIEW_SLIDE_LOCALE' },
  { from: '/builder/edit/:course_id/slides', to: 'BUILDER_EDIT_SLIDES' },
  { from: '/builder/edit/:course_id/slides/:locale', to: 'BUILDER_EDIT_SLIDES_LOCALE' },
  { from: '/builder/edit/:course_id/slide/:slide_id', to: 'BUILDER_EDIT_SLIDE' },
  { from: '/builder/edit/:course_id/slide/:slide_id/:locale', to: 'BUILDER_EDIT_SLIDE_LOCALE' },
  { from: '/company/:companyId/policies', to: 'OUTSTANDING_ACTIVITIES' }
].map(({ from, to }) => {
  const route = routesIdMap[to]
  return {
    from,
    to: route?.path ?? to
  }
})

const getRouteGroup = group => routes.filter(route => route.groups && route.groups.includes(group)).map(route => route.path)

const MAIN = getRouteGroup('MAIN')
const PRE_SIGN_IN = getRouteGroup('PRE_SIGN_IN')
const SESSION_LINKED = [...MAIN, ...PRE_SIGN_IN]

const PORTAL = getRouteGroup('PORTAL')
const PORTAL_MAIN = getRouteGroup('PORTAL_MAIN')

export const matchRoute = (pathname, { strict, exact } = {}) =>
  routesPathMap[pathname] || routes.find(({ path }) => matchPath(pathname, { path, strict, exact }))

export default {
  ...routesIdToPathMap,
  MAIN,
  PRE_SIGN_IN,
  SESSION_LINKED,
  PORTAL,
  PORTAL_MAIN
}
