import _isString from 'lodash/isString'

/*
CLIENT_ENV constant for defining app environment

process.env.NODE_ENV is set by react-scripts and is set as follows:
- 'development' on the hot loading dev server (npm start)
- 'test' on the test script (npm test)
- 'production' on a production (npm run build:app or react-scripts build)
This will not be pulled from our runtime env vars (window.__USECURE_CONFIG__) as it indicates whether or not this is a production build

Our REACT_APP_CLIENT_ENV env var from window.__USECURE_CONFIG__ will used instead if set

This allows use of a production build script locally and on staging but without production only functionality e.g. use of configured app domain in links
*/

const {
  REACT_APP_CLIENT_ENV, REACT_APP_API_HOST, REACT_APP_FORCE_SSL, REACT_APP_USECURE_APP_HOST,
  REACT_APP_FORCE_VIMEO_PLAYER, REACT_APP_ENABLE_REPORT_CENTRE, REACT_APP_REPORT_CENTRE_PREVIEW,
  REACT_APP_ENABLE_MESSAGE_INJECTION, REACT_APP_UBREACH_V2_ENABLED,
  REACT_APP_SSO_METHODS, REACT_APP_SSO_ENABLED, REACT_APP_APP_SUBDOMAIN, REACT_APP_API_SUBDOMAIN
} = window.__USECURE_CONFIG__ || {}

export const CLIENT_ENV = REACT_APP_CLIENT_ENV || process.env.NODE_ENV || 'development'
export const IN_DEV = CLIENT_ENV === 'development'

export const API_HOST = REACT_APP_API_HOST || 'localhost:8000'
export const FORCE_SSL = REACT_APP_FORCE_SSL === 'true'
export const USE_SSL = !IN_DEV || FORCE_SSL
export const APP_SUBDOMAIN = REACT_APP_APP_SUBDOMAIN || 'app'
export const API_SUBDOMAIN = REACT_APP_API_SUBDOMAIN || 'api'

export const USECURE_APP_HOST = REACT_APP_USECURE_APP_HOST
export const USE_DEFAULT_WHITELABELLING = CLIENT_ENV === 'production' && USECURE_APP_HOST && window.location.host !== USECURE_APP_HOST
export const USE_USECURE_BRANDING = !USE_DEFAULT_WHITELABELLING

export const FORCE_VIMEO_PLAYER = REACT_APP_FORCE_VIMEO_PLAYER === 'true'

// Client Side Feature Flags
// Temporary env var to enable/disable report centre during development
export const ENABLE_REPORT_CENTRE = REACT_APP_ENABLE_REPORT_CENTRE !== 'false'
// Temporary env var to enable/disable Preview tag on Report Centre and Performance Report functionality
export const REPORT_CENTRE_PREVIEW = REACT_APP_REPORT_CENTRE_PREVIEW === 'true'
// Temporary env var to show message injection functionality
export const ENABLE_MESSAGE_INJECTION = REACT_APP_ENABLE_MESSAGE_INJECTION === 'true'
// Temporary env var to enable uBreach v2 functionality
export const UBREACH_V2_ENABLED = REACT_APP_UBREACH_V2_ENABLED === 'true'
// SSO Feature Flags
const SSO_ENABLED = REACT_APP_SSO_ENABLED === 'true'
const SSO_METHODS = _isString(REACT_APP_SSO_METHODS) ? REACT_APP_SSO_METHODS.split(',') : []
export const SSO_SAML_ENABLED = SSO_ENABLED && SSO_METHODS.includes('saml')
export const SSO_MS_ENABLED = SSO_ENABLED && SSO_METHODS.includes('microsoft')
export const SSO_GOOGLE_ENABLED = SSO_ENABLED && SSO_METHODS.includes('google')
export const SSO_ALLOWED = SSO_SAML_ENABLED || SSO_MS_ENABLED || SSO_GOOGLE_ENABLED
export const DEFAULT_REGION = window.__USECURE_CONFIG__.REACT_APP_DEFAULT_REGION
export const REGION_ID = window.__USECURE_CONFIG__.REACT_APP_REGION_ID
