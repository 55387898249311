import React, { useMemo } from 'react'
import Intercom from './Intercom'
import _get from 'lodash/get'
import { useQuery } from 'react-apollo'

import { GET_INTERCOM_ME } from '../Queries/Users'
import { REGION_ID, DEFAULT_REGION } from '../../constants/environment'

const intercomAppId = window.__USECURE_CONFIG__.REACT_APP_INTERCOM_APP_ID

const UserIntercom = ({ session, settings }) => {
  const { data: intercomMeData } = useQuery(GET_INTERCOM_ME, { skip: !intercomAppId })

  const intercomEnabled = useMemo(() => {
    const { intercomEnabled } = _get(intercomMeData, 'me.user.company') || {}
    return intercomEnabled
  }, [intercomMeData])

  const intercomMeDataCompany = useMemo(() => {
    const {
      accountType,
      createdAt,
      totalCreatedPolicies,
      totalCustomBuiltCourses,
      simulationResultSentCount,
      uBreachProUpgradeDate,
      settings,
      intercomStatus,
      billingSettings
    } = _get(intercomMeData, 'me.user.company') || {}
    const { trialUpgradeDate, autoSubscription } = settings || {}
    const company = {
      'Auto Enrol Enabled': autoSubscription,
      'Live Policies': totalCreatedPolicies,
      'Custom Courses': totalCustomBuiltCourses,
      'Simulated Phishing Emails Sent': simulationResultSentCount,
      'uBreach Pro Upgrade Date': uBreachProUpgradeDate,
      Status: intercomStatus,
      'Billing Currency': billingSettings?.currency || null
    }
    if (accountType === 'tenant') {
      company['Trial Inception'] = createdAt
      if (session.planName !== 'freeTrial') company['Trial Upgrade Date'] = trialUpgradeDate
    }
    return company
  }, [intercomMeData, session])

  const { enableProspects } = settings || {}

  let accountType = session.parentCompanyId ? `non-direct ${session.accountType}` : session.accountType
  let partner = session.partner
  let prospectsEnabled = session.accountType === 'msp' ? enableProspects === true : null

  // 'Manager Tenants' are companies are setup as MSPs but are really customers using MSP functionality
  if (session.managerTenant) {
    accountType = 'tenant'
    partner = false
    prospectsEnabled = null
  }

  const uBreachProEnabled = settings?.uBreachProEnabled === true
  const uBreachProDomainCount = uBreachProEnabled ? (settings?.uBreachProSettings?.breachDomains ?? []).length : null

  const user = {
    name: `${session.firstName} ${session.lastName}`,
    email: session.email,
    company: {
      company_id: session.companyId,
      name: session.companyName,
      plan: session.planName === 'premium' ? 'custom' : session.planName,
      'Account Type': accountType,
      'Trial Expiry': session.expires || null,
      Partner: partner,
      'Total Learner Count': session.currentLearners,
      'Active Learner Count': session.activeLearnerCount,
      Distributor: session.distributorName,
      'Distributor ID': session.distributorId,
      MSP: session.mspName,
      'MSP ID': session.mspId,
      'Prospects Enabled': prospectsEnabled,
      'uBreach Pro Enabled': settings?.uBreachProEnabled === true,
      'uBreach Pro Domain Count': uBreachProDomainCount,
      Region: session.isUsecure ? DEFAULT_REGION : REGION_ID
    },
    'Auth Risk': session.authRisk,
    UserComms: session.commsType,
    user_id: session.userId,
    user_hash: session.intercomHash,
    language: session.locale
  }
  // merging the two company objects together to include values set by intercomMeData
  if (intercomMeData) {
    user.company = Object.assign({}, intercomMeDataCompany, user.company)
  }
  // Utilising a hybrid model way to retrieve company attribute data.
  if (!intercomAppId || !intercomEnabled) {
    return null
  }

  return (
    <Intercom appID={intercomAppId} {...user} />
  )
}

export default UserIntercom
