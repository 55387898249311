import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react'
import I18n from 'i18n-js'
import { Divider, Button, message } from 'antd'
import styled from 'styled-components'

import { creators as viewActions } from '../../state/actions/view'
import ExportServiceDownloadButton from '../../components/common/ExportServiceDownloadButton'

import { downloadFileFromGet, downloadFileFromPost } from '../../helpers/downloads'
import useGlobalState from '../../hooks/useGlobalState'
import ExportServiceDownloadModal from '../../components/Modals/ExportServiceDownloadModal'

import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

export const DescriptionContainer = styled.div`
  margin-bottom: 20px;
`
const ReportContainer = styled.div`
  margin-bottom: 30px;
`

const UBREACH_V2_ENABLED = window.__USECURE_CONFIG__.REACT_APP_UBREACH_V2_ENABLED === 'true'

const trOpt = { scope: 'reports.reportCentre.excelReports' }
const DEFAULT_VISIBILITY = {
  uPhish: true,
  uLearn: true,
  uBreach: true,
  uPolicy: true,
  uService: false,
  uBreachProUsage: false
}

const ExcelReport = ({ companyId, name, description, downloadLabel = I18n.t('reports.common.downloadReport'), type = 'export', file, fileType = 'xlsx', jobData, endpoint, setLoadingVisible, modalRef }) => {
  const downloadFile = useCallback(async () => {
    setLoadingVisible(true)

    if (type === 'get' && endpoint && file && companyId) {
      await downloadFileFromGet({ endpoint, file, companyId, params: { companyId } })
    } else if (type === 'post' && endpoint && file && companyId) {
      await downloadFileFromPost({ endpoint, file, data: { isCompanyExport: true, companyId } })
    } else {
      // This would only be shown if there was a bug in the report definition that generates this component's props
      message.error(I18n.t('common.anErrorOccurred'))
    }

    setLoadingVisible(false)
  }, [type, endpoint, file, companyId, setLoadingVisible])

  return (
    <ReportContainer>
      <h3>{name}</h3>
      <p>{description}</p>
      {type === 'export' && (
        <ExportServiceDownloadButton
          icon='download'
          btnType={null}
          fileName={file}
          jobData={{ ...(jobData || {}), companyId }}
          {...{ fileType, modalRef }}
        >
          {downloadLabel}
        </ExportServiceDownloadButton>
      )}
      {(type === 'get' || type === 'post') && <Button icon='download' onClick={downloadFile}>{downloadLabel}</Button>}
    </ReportContainer>
  )
}
const ExcelReports = ({ companyId, settings, uBreachProEnabled }) => {
  const { hasAllSessionPermissions, hasAnySessionPermission } = useHasSessionPermission()
  const [visibility, setVisibility] = useState(DEFAULT_VISIBILITY)
  const exportServiceDownloadModalRef = useRef(null)

  const { companyId: sessionCompanyId, accountType, setLoadingVisible } = useGlobalState(
    useCallback(state => ({
      companyId: state.session?.companyId,
      accountType: state.session?.accountType
    }), []),
    useCallback(dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    }), [])
  )

  useEffect(() => {
    const isOwnCompany = companyId === sessionCompanyId
    const companyImpersonateAllowed = hasAllSessionPermissions([permissions.COMPANY_IMPERSONATE])

    if (settings) {
      const hasUServicePermission = isOwnCompany && hasAllSessionPermissions([permissions.COMPANY_LIST])
      const hasSuperUServicePermission = isOwnCompany && hasAllSessionPermissions([permissions.COMPANY_SUPER_LIST])
      const hasUBreachProUsagePermission = isOwnCompany && hasAllSessionPermissions([permissions.SETTINGS_UBREACH_READ, permissions.COMPANY_LIST])
      const hasUBreachPermission = isOwnCompany ? hasAllSessionPermissions([permissions.BREACH_REPORT]) : companyImpersonateAllowed
      const hasULearnPermission = isOwnCompany ? hasAllSessionPermissions([permissions.COURSE_REPORT]) : companyImpersonateAllowed
      const hasUPhishPermission = isOwnCompany ? hasAllSessionPermissions([permissions.SIMULATION_REPORT]) : companyImpersonateAllowed
      const hasUPolicyPermission = isOwnCompany ? hasAllSessionPermissions([permissions.POLICY_REPORT]) : companyImpersonateAllowed

      setVisibility({
        uLearn: settings.uLearn !== false && hasULearnPermission,
        uPhish: settings.uPhish !== false && hasUPhishPermission,
        uBreach: settings.uBreachEnabled !== false && hasUBreachPermission,
        uBreachProUsage: hasUBreachProUsagePermission && uBreachProEnabled === true && (accountType === 'distributor' || accountType === 'msp'),
        uPolicy: settings.uPolicy !== false && hasUPolicyPermission,
        uService: isOwnCompany && ((accountType === 'distributor' && hasUServicePermission) || (accountType === 'msp' && hasUServicePermission) || hasSuperUServicePermission)
      })
    }
  }, [settings, accountType, companyId, sessionCompanyId, uBreachProEnabled, hasAllSessionPermissions])

  const excelReportsList = useMemo(() => {
    const excelReports = []

    if (visibility.uService) {
      if (hasAllSessionPermissions([permissions.COMPANY_SUPER_LIST])) {
        excelReports.unshift({
          key: 'distributorBillableUsage',
          name: I18n.t('distributorBillableUsage.name', trOpt),
          description: I18n.t('distributorBillableUsage.description', trOpt),
          downloadLabel: I18n.t('distributorBillableUsage.download', trOpt),
          file: 'distributor-billable-usage.xlsx',
          jobData: { reportType: 'distributorBillableUsage' }
        })
        excelReports.unshift({
          key: 'salesReport',
          name: I18n.t('salesReport.name', trOpt),
          description: I18n.t('salesReport.description', trOpt),
          downloadLabel: I18n.t('salesReport.download', trOpt),
          file: 'sales-report.xlsx',
          jobData: { reportType: 'salesReport' }
        })
      }

      if (accountType !== 'msp') {
        excelReports.unshift({
          key: 'prospectsReport',
          name: I18n.t('prospectsReport.name', trOpt),
          description: I18n.t('prospectsReport.description', trOpt),
          downloadLabel: I18n.t('prospectsReport.download', trOpt),
          file: 'prospects-report.xlsx',
          jobData: { reportType: 'prospectsReport' }
        })
      }

      excelReports.unshift({
        key: 'accountsOverview',
        name: I18n.t('accountsOverview.name', trOpt),
        description: I18n.t('accountsOverview.description', trOpt),
        downloadLabel: I18n.t('accountsOverview.download', trOpt),
        file: 'accounts-overview.xlsx',
        fileType: 'xlsx',
        jobData: { reportType: 'accountsOverview' }
      })

      excelReports.unshift({
        key: 'uServiceDivider',
        divider: true,
        title: I18n.t('common.uService')
      })
    }

    if (hasAnySessionPermission([permissions.LEARNER_REPORT, permissions.GROUP_REPORT])) {
      excelReports.push({
        key: 'usersDivider',
        divider: true,
        title: I18n.t('common.users')
      })
      if (hasAllSessionPermissions([permissions.LEARNER_REPORT])) {
        excelReports.push({
          key: 'learnersReport',
          name: I18n.t('learnersReport.name', trOpt),
          description: I18n.t('learnersReport.description', trOpt),
          downloadLabel: I18n.t('learnersReport.download', trOpt),
          file: 'users-report.xlsx',
          jobData: { reportType: 'learnersReport' }
        })
      }
      if (hasAllSessionPermissions([permissions.GROUP_REPORT])) {
        excelReports.push({
          key: 'groupManagers',
          name: I18n.t('groupManagers.name', trOpt),
          description: I18n.t('groupManagers.description', trOpt),
          downloadLabel: I18n.t('groupManagers.download', trOpt),
          file: 'group-managers-report.xlsx',
          jobData: { reportType: 'groupManagersReport' }
        })
      }
    }

    if (visibility.uPhish) {
      excelReports.push({
        key: 'uPhishDivider',
        divider: true,
        title: I18n.t('common.uPhish')
      }, {
        key: 'simulationReport',
        name: I18n.t('simulationReport.name', trOpt),
        description: I18n.t('simulationReport.description', trOpt),
        downloadLabel: I18n.t('simulationReport.download', trOpt),
        jobData: { reportType: 'simulationDataExport', exportType: 'allSimulations' },
        file: 'simulation-events.xlsx'
      }, {
        key: 'learnerSimulationExport',
        name: I18n.t('learnerSimulationExport.name', trOpt),
        description: I18n.t('learnerSimulationExport.description', trOpt),
        download: I18n.t('learnerSimulationExport.download', trOpt),
        jobData: { reportType: 'simulationDataExport', exportType: 'allLearners' },
        file: 'simulations.xlsx'
      })
    }
    if (visibility.uLearn) {
      excelReports.push({
        key: 'uLearnDivider',
        divider: true,
        title: I18n.t('common.uLearn')
      }, {
        key: 'outstandingCourses',
        name: I18n.t('outstandingCourses.name', trOpt),
        description: I18n.t('outstandingCourses.description', trOpt),
        downloadLabel: I18n.t('outstandingCourses.download', trOpt),
        file: 'outstanding.xlsx',
        jobData: { reportType: 'courseDataExport', exportType: 'outstandingCourses' }
      }, {
        key: 'participationGrid',
        name: I18n.t('participationGrid.name', trOpt),
        description: I18n.t('participationGrid.description', trOpt),
        downloadLabel: I18n.t('participationGrid.download', trOpt),
        file: 'participation-grid.xlsx',
        jobData: { reportType: 'courseParticipationGridExport' }
      }, {
        key: 'learnerCourseExport',
        name: I18n.t('learnerCourseExport.name', trOpt),
        description: I18n.t('learnerCourseExport.description', trOpt),
        downloadLabel: I18n.t('learnerCourseExport.download', trOpt),
        file: 'courses.xlsx',
        jobData: { reportType: 'courseDataExport', exportType: 'allLearners' }
      }, {
        key: 'uLearnReportExport',
        name: I18n.t('uLearnReportExport.name', trOpt),
        description: I18n.t('uLearnReportExport.description', trOpt),
        downloadLabel: I18n.t('uLearnReportExport.download', trOpt),
        file: 'courses-report.xlsx',
        jobData: { reportType: 'uLearnReportExport', companyId }
      })
    }
    if (visibility.uBreach) {
      excelReports.push({
        key: 'uBreachDivider',
        divider: true,
        title: I18n.t('common.uBreach')
      })
      if (UBREACH_V2_ENABLED) {
        excelReports.push({
          key: 'breachesReport',
          name: I18n.t('breachesReport.name', trOpt),
          description: I18n.t('breachesReport.description', trOpt),
          downloadLabel: I18n.t('breachesReport.download', trOpt),
          file: 'breaches{{ts}}.xlsx',
          jobData: { reportType: 'uBreachExport', companyId }
        })
      } else {
        excelReports.push({
          key: 'breachesReport',
          name: I18n.t('breachesReport.name', trOpt),
          description: I18n.t('breachesReport.description', trOpt),
          downloadLabel: I18n.t('breachesReport.download', trOpt),
          endpoint: '/api/uBreach/breaches.xlsx',
          file: 'breaches{{ts}}.xlsx',
          type: 'get'
        })
      }
    }
    if (visibility.uBreachProUsage) {
      excelReports.push({
        key: 'uBreachProDivider',
        divider: true,
        title: I18n.t('common.uBreachPro')
      }, {
        key: 'uBreachProUsageReport',
        name: I18n.tWithProductNames('uBreachProUsageReport.name', trOpt),
        description: I18n.tWithProductNames('uBreachProUsageReport.description', trOpt),
        downloadLabel: I18n.tWithProductNames('uBreachProUsageReport.download', trOpt),
        file: 'ubreach-pro-usage-report.xlsx',
        jobData: { reportType: 'uBreachProUsageReportExport' }
      })
    }
    if (visibility.uPolicy) {
      excelReports.push({
        key: 'uPolicyDivider',
        divider: true,
        title: I18n.t('common.uPolicy')
      }, {
        key: 'outstandingPolicyExport',
        name: I18n.t('outstandingPolicyExport.name', trOpt),
        description: I18n.t('outstandingPolicyExport.description', trOpt),
        downloadLabel: I18n.t('outstandingPolicyExport.download', trOpt),
        file: 'outstanding-policy-report.xlsx',
        jobData: { reportType: 'policyDataExport', exportType: 'outstandingPolicies' }
      }, {
        key: 'learnerPolicyExport',
        name: I18n.t('learnerPolicyExport.name', trOpt),
        description: I18n.t('learnerPolicyExport.description', trOpt),
        downloadLabel: I18n.t('learnerPolicyExport.download', trOpt),
        file: 'policy_reports-{{ts}}.xlsx',
        jobData: { reportType: 'policyDataExport', exportType: 'allLearners' }
      })
    }

    return excelReports
  }, [visibility, companyId, hasAllSessionPermissions, hasAnySessionPermission, accountType])
  return (
    <>
      <ExportServiceDownloadModal ref={exportServiceDownloadModalRef} />
      <div>
        <h1>{I18n.t('excelReports', trOpt)}</h1>
        <DescriptionContainer>{I18n.t('pageDescription', trOpt)}</DescriptionContainer>
      </div>
      {excelReportsList.map((report) => {
        if (report.divider) {
          return <Divider key={report.key}><h2>{report.title}</h2></Divider>
        } else {
          return (
            <ExcelReport
              key={report.key} {...report} {...{ companyId, setLoadingVisible }}
              modalRef={exportServiceDownloadModalRef}
            />
          )
        }
      })}
    </>
  )
}

export default ExcelReports
