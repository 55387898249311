import React, { useState, useCallback, useEffect, useMemo } from 'react'
import I18n from 'i18n-js'
import { Button } from 'antd'

import routes from '../../constants/routes'
import { ListHeader, ListHeaderPanel } from '../common'
import CompaniesView from './CompaniesView'
import { ReportDownloadDropdown } from '../Reports'
import useLocalStorage from '../../hooks/useLocalStorage'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'uService' }

const ReportDownload = ({ hasAllSessionPermissions, accountType, userAccountType, enableProspects, enableRiskReportTenants }) => {
  const reports = []

  const hasListPermission = hasAllSessionPermissions([permissions.COMPANY_LIST])
  const hasSuperListPermission = hasAllSessionPermissions([permissions.COMPANY_SUPER_LIST])

  if (hasSuperListPermission || (hasListPermission && (userAccountType === 'distributor' || (userAccountType === 'msp' && accountType === 'tenant')))) {
    reports.push({ label: I18n.t('downloadAccountsOverview', trOpt), fileName: 'accounts-overview.xlsx', jobData: { reportType: 'accountsOverview' }, fileType: 'xlsx' })
  }

  if (hasSuperListPermission || (hasListPermission && userAccountType === 'distributor')) {
    reports.push({ label: I18n.t('downloadProspectsReport', trOpt), fileName: 'prospects-report.xlsx', jobData: { reportType: 'prospectsReport' }, fileType: 'xlsx' })
  }

  if (hasSuperListPermission) {
    reports.push({ label: I18n.t('downloadSalesReport', trOpt), fileName: 'sales-report.xlsx', jobData: { reportType: 'salesReport' }, fileType: 'xlsx' })
    reports.push({ label: I18n.t('downloadDistributorBillableUsageReport', trOpt), fileName: 'distributor-billable-usage.xlsx', jobData: { reportType: 'distributorBillableUsage' }, fileType: 'xlsx' })
  }

  return (
    <ReportDownloadDropdown {...{ reports }} />
  )
}

const configLeftPanel = ({ hasAllSessionPermissions, enableBillingExport, accountType, userAccountType, enableProspects, enableRiskReportTenants }) => {
  const hasBillingReadPermission = hasAllSessionPermissions([permissions.BILLING_READ])

  const panelEntries = enableBillingExport && hasBillingReadPermission ? ['billing-export'] : []

  panelEntries.push(<ReportDownload {...{ hasAllSessionPermissions, accountType, userAccountType, enableProspects, enableRiskReportTenants }} />)

  return panelEntries
}

const configRightPanel = ({ hasAllSessionPermissions, path, accountType, userAccountType, enableProspects, disableTenantCreation, disableProspectCreation }) => {
  const panelEntries = ['search'] // Search is always available

  const hasCreatePermission = hasAllSessionPermissions([permissions.COMPANY_CREATE])
  const hasSuperCreatePermission = hasAllSessionPermissions([permissions.COMPANY_SUPER_CREATE])

  const showCreateProspectCustomerMenu = userAccountType === 'msp' && enableProspects && !disableTenantCreation && !disableProspectCreation

  const showSingleCreateButton = userAccountType === 'distributor' || (
    userAccountType === 'msp' && (
      ((!accountType || accountType === 'tenant') && !disableTenantCreation) || // MSP are assumed to be viewing customers by default
    (accountType === 'prospect' && !disableProspectCreation)
    )
  )

  if (hasSuperCreatePermission) {
    path === routes.USERVICE ? panelEntries.push('addMenu') : panelEntries.push('add')
  } else if (hasCreatePermission && showCreateProspectCustomerMenu) {
    panelEntries.push('add-prospect') // add-prospect is a button
  } else if (hasCreatePermission && showSingleCreateButton) {
    panelEntries.push('add')
  }

  return panelEntries
}

const UServiceAllCompanies = ({
  title,
  loading,
  error,
  companies,
  companyId,
  userId,
  accountType,
  userAccountType,
  showExternalId,
  path,
  theme,
  enableProspects,
  enableRiskReportTenants,
  disableTenantDeletion,
  disableProspectDeletion,
  refetchQueries,
  upgradeProspectRefetchQueries,
  setPollingEnabled,
  disableTenantCreation,
  disableProspectCreation,
  enableBillingExport,
  refetch
}) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()

  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)

  const panelLeft = configLeftPanel({ hasAllSessionPermissions, enableBillingExport, accountType, userAccountType, enableProspects, enableRiskReportTenants })

  const panelRight = configRightPanel({ hasAllSessionPermissions, path, accountType, userAccountType, enableProspects, disableTenantCreation, disableProspectCreation })

  const storageId = useMemo(() => `uService|${companyId}|${userId}${accountType ? `|${accountType}` : ''}`, [userId, companyId, accountType])
  const { updateFromLocalStorage, updateLocalStorage } = useLocalStorage({ storageId })
  useEffect(() => {
    if (!updateFromLocalStorage) return
    updateFromLocalStorage({
      searchFilterText: updateSearchFilterText,
      filters: updateFilters,
      sorter: updateSorter,
      pagination: updatePagination
    })
  }, [updateFromLocalStorage, storageId])
  useEffect(() => {
    if (!updateLocalStorage) return
    updateLocalStorage({
      searchFilterText, filters, sorter, pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  const handleClearAllFiltersClick = useCallback(() => {
    updateFilters(null)
    updateSearchFilterText(null)
  }, [])

  return (
    <>
      <ListHeader align={title ? null : 'right'}>
        {title}
        <ListHeaderPanel align='right'>
          <Button icon={loading ? 'loading' : 'filter'} ghost type='danger' disabled={loading} onClick={handleClearAllFiltersClick}>{I18n.t('common.clearAllFilters')}</Button>
          <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={refetch}>{I18n.t('common.refresh')}</Button>
        </ListHeaderPanel>
      </ListHeader>
      <CompaniesView
        {...{
          loading,
          error,
          companies,
          companyId,
          userId,
          searchFilterText,
          updateSearchFilterText,
          sorter,
          updateSorter,
          filters,
          updateFilters,
          pagination,
          updatePagination,
          accountType,
          userAccountType,
          panelLeft,
          panelRight,
          showExternalId,
          path,
          theme,
          enableProspects,
          enableRiskReportTenants,
          disableTenantDeletion,
          disableProspectDeletion,
          refetchQueries,
          upgradeProspectRefetchQueries,
          setPollingEnabled
        }}
        openAddModal={path === routes.USERVICE_MSP_TYPE_CREATE}
      />
    </>
  )
}

export default UServiceAllCompanies
