import React, { useState, useImperativeHandle, useCallback, useRef } from 'react'
import I18n from 'i18n-js'
import { Modal, message } from 'antd'
import { generatePath } from 'react-router-dom'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import MutationForm from '../MutationForm/MutationForm'
import { GET_DOMAIN_SCAN_REPORT_ACCESS_TOKEN } from '../Queries/Reports'
import { validateEmail, validateDomain } from '../../helpers'
import routes from '../../constants/routes'
import { themes } from '../../theme'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'

const { main: defaultTheme } = themes
const trOpt = { scope: 'modals.createDomainScanReportModal' }

const CreateDomainScanReportModal = React.forwardRef(({ company, defaultTenantSettings }, ref) => {
  const [visible, setVisible] = useState(false)

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: async (record) => {
      setVisible(true)
    }
  }), [])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = useCallback(() => {
    setVisible(false)
    resetForm()
  }, [resetForm])

  const onSuccess = useCallback((result) => {
    message.success(I18n.t('successMessage', trOpt))

    const { data: { domainScanReportAccessToken: token } } = result
    const url = generatePath(routes.DOMAIN_SCAN_REPORT_CONTENT_VIEW, { token })
    window.open(url, '_blank')
    closeModal()
  }, [closeModal])

  return (
    <Modal
      visible={visible}
      title={I18n.t('title', trOpt)}
      onCancel={closeModal}
      afterClose={afterClose}
      footer={null}
    >
      <MutationForm
        ref={form}
        onSuccess={onSuccess}
        mutation={GET_DOMAIN_SCAN_REPORT_ACCESS_TOKEN}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={I18n.t('sumbitLabel', trOpt)}
        submitIcon='save'
        skipResetFieldsOnSubmit
        fields={[{
          id: 'name',
          label: I18n.t('companyName', trOpt)
        }, {
          id: 'domain',
          label: I18n.t('companyDomain', trOpt),
          validate: (field, value, errors) => {
            if (!validateDomain(value)) {
              errors.push(I18n.t('common.invalidDomainError'))
            }
          },
          required: true
        }, {
          id: 'logo',
          type: 'image',
          label: I18n.t('companyLogo', trOpt),
          required: true,
          defaultValue: _get(company, 'settings.appThemeLogo', defaultTenantSettings?.appThemeLogo || defaultTheme.appThemeLogo)
        },
        {
          id: 'documentLogo',
          type: 'image',
          label: I18n.t('documentLogo', trOpt),
          required: true,
          defaultValue: _get(company, 'settings.reportDocumentLogo', defaultTenantSettings?.reportDocumentLogo || defaultTheme.reportDocumentLogo)
        },
        {
          id: 'targetEmails',
          label: I18n.t('targetEmails', trOpt),
          type: 'textTags',
          validateTag: value => {
            if (!validateEmail(value)) {
              return I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError')
            }
          },
          defaultValue: [],
          required: false,
          extra: I18n.t('targetEmailsExtra', trOpt),
          maxValues: 5
        }]}
      />
    </Modal>
  )
})

export default connect(
  state => _pick(selectors.settings.get(state), ['defaultTenantSettings'])
)(CreateDomainScanReportModal)
