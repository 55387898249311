import React, { Component } from 'react'
import styled from 'styled-components'
import { Alert, Button, Divider } from 'antd'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import { Link, matchPath } from 'react-router-dom'

import { MutationFormFooter } from '../../components/MutationForm'
import MutationForm from '../../components/MutationForm/MutationForm'
import { SignInContainer, SignInHeader, BackLink } from '../../components/SignIn'
import { connect, withLogin, withAppTitleAndIcons, withConsumer } from '../../hocs'
import { creators as viewActions } from '../../state/actions/view'
import { showErrors, validateEmail, UsecureError, getErrorCode } from '../../helpers'
import { SIGN_IN, GET_SAML_AUTH_START_TOKEN } from '../../components/Queries/Users'
import { adminChannel } from '../../helpers/session'
import routes from '../../constants/routes'
import { creators as settingsActions } from '../../state/actions/settings'
import { creators as sessionActions } from '../../state/actions/session'
import { SSO_SAML_ENABLED, USE_USECURE_BRANDING } from '../../constants/environment'
import { captureSentrySignInError, captureSentryError } from '../../helpers/sentry'
import { apiUrl } from '../../apollo-client/common'
import { showSAMLAuthErrors } from '../../helpers/sso'

const trOpt = { scope: 'signIn' }
const trForgottenPasswordOpt = { scope: 'signIn.forgottenPasswordLink' }

const ForgottenPasswordLinkContainer = styled.div`
  margin-bottom: 1.72rem;
  text-align: right;
`

const StyledForgottenPasswordLink = styled(Link)`
  color: ${props => props.theme.primary};
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
`

const StyledSignUpAlert = styled(Alert)`
  margin-bottom: 15px;
`

const RegisterLinkContainer = styled.div`
  height: 0;
  text-align: center;
`

const RegisterLink = styled.a`
  position: relative;
  top: -40px;
`

const OptionDivider = styled(Divider)`
  color: ${({ theme }) => theme.primary}!important;
  &:after {
    border-top: 1px solid ${({ theme }) => theme.primary}!important;
  }
  &:before {
    border-top: 1px solid ${({ theme }) => theme.primary}!important;
  }
`

class SignIn extends Component {
  constructor (props) {
    super(props)

    this.state = {
      screen: 'base'
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.mutateValues = this.mutateValues.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleFailure = this.handleFailure.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.handleChannelMessage = this.handleChannelMessage.bind(this)
    this.handleSAMLSSOClick = this.handleSAMLSSOClick.bind(this)
    this.handleSAMLSSOBackClick = this.handleSAMLSSOBackClick.bind(this)
    this.handleSAMLSSOSubmit = this.handleSAMLSSOSubmit.bind(this)
  }

  mutateValues (values) {
    return {
      ...values,
      targetPath: this.getTargetPath()
    }
  }

  handleSubmit () {
    this.props.setLoadingVisible(true)
  }

  async handleSuccess (mutationResult) {
    const { is2FAEnabled, token } = mutationResult?.data?.signIn ?? {}
    if (is2FAEnabled && token) {
      // Admin User with MFA, navigate to MFA to complete auth
      // Set pre auth token in session global state
      this.props.setMFAToken(token)
      // Navigate to routes.MFA_LOGIN
      this.props.history.push(routes.MFA_LOGIN)
    } else {
      // Admin User with No MFA, initialise session and navigate to desired page
      try {
        await this.props.onSuccess(mutationResult, 'data.signIn.token', null)
        this.props.setLoadingVisible(false)
      } catch (e) {
        console.error('SignIn.OnSuccess - Error', e)
        this.handleFailure(e)
      }
    }
  }

  handleFailure (e) {
    this.props.setLoadingVisible(false)
    captureSentrySignInError(e, { msg: 'SignIn.handleFailure - Error' })
    showErrors(e)
  }

  renderFooter ({ submitLabel, loading, footerAlign }) {
    return (
      <>
        <ForgottenPasswordLinkContainer>
          <StyledForgottenPasswordLink to={routes.FORGOTTEN_PASSWORD}>{I18n.t('forgot', trForgottenPasswordOpt)}</StyledForgottenPasswordLink>
        </ForgottenPasswordLinkContainer>
        <MutationFormFooter footerAlign={footerAlign}>
          <Button type='primary' ghost disabled={loading} htmlType='submit'>{submitLabel}</Button>
        </MutationFormFooter>
      </>
    )
  }

  handleSAMLSSOClick () {
    this.setState({ screen: 'samlSSO' })
  }

  handleSAMLSSOBackClick () {
    this.setState({ screen: 'base' })
  }

  getTargetPath () {
    // Return pathname if it isn't root i.e. routes.HOME
    if (!matchPath(window.location.pathname, { path: routes.HOME, strict: true, exact: true })) {
      return window.location.pathname
    }
  }

  async handleSAMLSSOSubmit ({ email }) {
    try {
      this.props.setLoadingVisible(true)
      const variables = { authType: 'adminUser', email, origin: window.location.origin, targetPath: this.getTargetPath() }

      const { data } = await this.props.client.mutate({ mutation: GET_SAML_AUTH_START_TOKEN, variables })
      const startToken = data?.getSAMLAuthStartToken
      if (startToken) {
        window.location.href = `${apiUrl}/auth/saml/${startToken}`
      } else {
        // AUP-NTR - Admin User Portal, No Token Returned
        throw new UsecureError(I18n.t('samlSSOError', trOpt), { errorCode: 'SAML-AUP-NTR' })
      }
    } catch (e) {
      captureSentryError(e, { msg: `SignIn.handleSAMLSSOSubmit - ERROR - ${getErrorCode(e, 'NO_CODE')}` })
      // Fallback error - AUP-UNK - Admin User Portal, UNKnown error
      showSAMLAuthErrors(e, { trOpt, errorCodePrefix: 'AUP' })
      this.props.setLoadingVisible(false)
    }
  }

  // Listen for user signing in on different tab/window
  handleChannelMessage (e) {
    if (e.data?.id === 'sessionRefresh') {
      this.props.refreshSessionState()
    }
  }

  // adminChannel will be null if BroadcastChannel is not supported
  componentDidMount () {
    this.props.updateSettings({ useSingleFavicon: true })
    if (adminChannel) adminChannel.addEventListener('message', this.handleChannelMessage, false)
  }

  componentWillUnmount () {
    if (adminChannel) adminChannel.removeEventListener('message', this.handleChannelMessage, false)
  }

  render () {
    const { search } = this.props.location
    const { screen } = this.state
    const queryParams = new URLSearchParams(search)

    return (
      // Query string so the marketing site register form can explain they need to verify their email address
      // rfh = redirect from homepage
      <SignInContainer>
        {(queryParams.get('rfh') === 'true' || queryParams.get('register') === 'true') && (
          <StyledSignUpAlert message={I18n.t('thankYouForSigningUp', trOpt)} type='success' />
        )}
        <SignInHeader bottomMargin={screen === 'samlSSO' ? '1.2em' : undefined}>{I18n.t('common.login')}</SignInHeader>
        {screen === 'base' && (
          <>
            {USE_USECURE_BRANDING && (
              <RegisterLinkContainer>
                <RegisterLink href='https://www.usecure.io/free-account'>{I18n.t('createAnAccount', trOpt)}</RegisterLink>
              </RegisterLinkContainer>
            )}
            <MutationForm
              mutation={SIGN_IN}
              mutateValues={this.mutateValues}
              onSubmit={this.handleSubmit}
              onSuccess={this.handleSuccess}
              onFailure={this.handleFailure}
              submitLabel={I18n.t('common.login')}
              fields={[
                { id: 'login', placeholder: I18n.t('common.fields.email'), type: 'email', required: true },
                { id: 'password', placeholder: I18n.t('common.fields.password'), type: 'password', required: true }
              ]}
              footer={this.renderFooter}
              focusOnMount
            />
            {SSO_SAML_ENABLED && (
              <>
                <OptionDivider>{I18n.t('common.or')}</OptionDivider>
                <Button type='primary' ghost block onClick={this.handleSAMLSSOClick}>{I18n.t('loginWithSSO', trOpt)}</Button>
              </>
            )}
          </>
        )}
        {screen === 'samlSSO' && (
          <>
            <BackLink type='link' onClick={this.handleSAMLSSOBackClick}>{I18n.t('common.goBack')}</BackLink>
            <MutationForm
              onSubmit={this.handleSAMLSSOSubmit}
              btnBlock
              submitLabel={I18n.t('loginWithSSO', trOpt)}
              fields={[{
                id: 'email',
                type: 'email',
                placeholder: I18n.t('common.fields.email'),
                required: true,
                validate: (field, value, errors) => {
                  if (!validateEmail(value)) {
                    errors.push(I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError'))
                  }
                }
              }]}
              focusOnMount
              silentFailure
              skipResetFieldsOnSubmit
            />
          </>
        )}
      </SignInContainer>
    )
  }
}

export default compose(
  connect(
    undefined,
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading)),
      updateSettings: settings => dispatch(settingsActions.update(settings)),
      setMFAToken: mfaToken => dispatch(sessionActions.updateMFAToken(mfaToken))
    })
  ),
  withLogin,
  withAppTitleAndIcons({ isSignInPage: true }),
  withConsumer
)(SignIn)
