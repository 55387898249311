import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, generatePath } from 'react-router-dom'
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'

import { connect } from '../hocs'
import routes from '../constants/routes'
import { isRouteAllowed } from '../helpers'
import { hasAllPermissions, hasAnyPermission } from '../helpers/hasPermission'
import { getSessionAndSettings } from '../state/selectors'
import { permissions } from '../constants/permissions'

const ProtectedRoute = ({ path, session, settings, component: Component, fallbackComponent: FallbackComponent, redirect = routes.HOME, debugLogger, sessionType = 'admin', ...rest }) => {
  if (!(session.planValid || (!session.planValid && session.inDisguise) || (_isArray(path) && path.includes(routes.HOLDING)))) {
    if (_isFunction(debugLogger)) debugLogger({ path, holding: true, session, settings })
    return <Redirect to={routes.HOLDING} />
  }

  const allowed = isRouteAllowed(session, settings, path, sessionType)
  const availablePermisisons = session.permissions || []

  const hasHomepageAccess = (
    // Users with any of the following permissions are allowed to access the homepage
    hasAnyPermission({
      availablePermisisons,
      requiredPermissions: [
        permissions.BREACH_REPORT,
        permissions.COURSE_REPORT,
        permissions.POLICY_REPORT,
        permissions.SIMULATION_REPORT
      ]
    }) &&
    // Partner users without platform access are not allowed to access the homepage
    !(session.partner && !settings.platformAccess) &&
    // Prospect users are not allowed to access the homepage
    !(session.accountType === 'prospect')
  )

  const originalRedirect = redirect
  // If attempting to redirect to the homepage, and the user does not have access, decide where to send them
  if (!allowed && !hasHomepageAccess && redirect === routes.HOME) {
    if (session.partner && hasAllPermissions({ availablePermisisons, requiredPermissions: [permissions.COMPANY_LIST] })) {
      // Partners with the COMPANY_LIST permission are redirected to uService
      redirect = session.accountType === 'msp' && settings.enableProspects === true ? generatePath(routes.USERVICE_MSP_TYPE, { accountType: 'customers' }) : routes.USERVICE
    } else if (session.accountType === 'prospect') {
      // Prospect users are redirected to the risk report preview
      redirect = routes.RISK_REPORT_PREVIEW
    }
  }

  if (_isFunction(debugLogger)) debugLogger({ path, holding: false, allowed, session, settings })
  const RouteComponent = allowed ? Component : redirect === originalRedirect && FallbackComponent
  return (
    (RouteComponent || (allowed && _isFunction(rest.render)))
      ? <Route path={path} {...rest} component={RouteComponent} />
      : <Redirect to={redirect} />
  )
}

ProtectedRoute.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  settings: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  // TODO component or render is required
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
  render: PropTypes.func,
  fallbackComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
  redirect: PropTypes.string
}

export default connect(
  getSessionAndSettings
)(ProtectedRoute)
