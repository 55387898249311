import gql from 'graphql-tag'

export const GET_USERS = gql`
query {
  users {
    id
    name
    email
    commsType
    roles
    is2FAEnabled
  }
}
`
export const GET_USER = gql`
query ($id: ID!) {
  user(id: $id) {
    id
    name
    firstName
    lastName
    email
    locale
    commsType
    roles,
    timezone
  }
}
`

export const DELETE_USER = gql`
  mutation($userId: ID!) {
    deleteUser(userId: $userId)
  }
`

export const CREATE_USER = gql`
  mutation($firstName: String!, $lastName: String!, $email: String!, $commsType: CommsType, $locale: LanguageCode!, $roles: [AdminUserRole], $timezone: String) {
    createUser(firstName: $firstName, lastName: $lastName, email: $email, commsType: $commsType, locale: $locale, roles: $roles, timezone: $timezone) {
      id
      name
      firstName
      lastName
      email
      locale
      commsType
      roles
      timezone
    }
  }
`

export const UPDATE_USER = gql`
  mutation($id: ID!, $firstName: String!, $lastName: String!, $email: String!, $commsType: CommsType, $locale: LanguageCode!, $roles: [AdminUserRole], $timezone: String) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email, commsType: $commsType, locale: $locale, roles: $roles, timezone: $timezone) {
      id
      name
      firstName
      lastName
      email
      locale
      commsType
      roles
      timezone
    }
  }
`

export const VERIFY_EMAIL = gql`
  mutation($token: String!) {
    verifyEmail(token: $token)
  }
`

export const UPDATE_PASSWORD = gql`
  mutation ($oldPassword: String!, $password: String!) {
    updatePassword(oldPassword: $oldPassword, password: $password)
  }
`
export const GET_ME = gql`
  {
    me {
      user {
        id
        name
        firstName
        lastName
        email
        roles
        companyId,
        intercomHash
        commsType
        locale
        company {
          name
          accountType
          partner
          domain
          settings
          disableTenantCreation
          disableTenantDeletion
          disableProspectCreation
          disableProspectDeletion
          enableProspects
          enableRiskReportTenants
          enableCreateDomainScan
          phishAlertEnabled
          helpCentreEnabled
          intercomEnabled
          riskReportUPhishCompletionType
          distiMSPInvoicesEnabled
          learnerAutoEnrolExcludeEnabled
          parentDefaultSettings
          editExternalId
          locale
          contentLocales
          timezone
          isUsecure
          appProductNames {
            uLearn
            uPhish
            uBreach
            uBreachPro
            uPolicy
          }
          plan {
            planName
            expires
            valid
            currentLearners
          }
          billingType
          paymentProvided
          parentCompanyId
          activeLearnerCount
          billingSettings {
            startDate
          }
          managerTenant
          parentCompany {
            id
            name
            uBreachProEnabled
            parentCompany {
              id
              name
              uBreachProEnabled
            }
          }
          riskReport {
            status
            simulationId
          }
          uBreachProEnabled
          userAuthOptions {
            password
          }
        }
      }
      inDisguise
      originalPermissions
      multipleDisguises
      permissions
      authRisk
    }
  }
`

export const GET_ACCOUNT = gql`
  query {
    me {
      user {
        id
        email
        firstName
        lastName
        isVerified
        commsType
        locale
        profile {
          addressLine1
          addressLine2
          postcode
        }
        timezone
      }
    }
  }
`

export const UPDATE_ACCOUNT = gql`
  mutation (
    $firstName: String, $lastName: String,
    $email: String, $addressLine1: String, $addressLine2: String, $postcode: String,
    $commsType: CommsType, $locale: LanguageCode, $timezone: String
  ) {
    updateMe (
      firstName: $firstName
      lastName: $lastName
      email: $email
      commsType: $commsType
      locale: $locale
      profile: {
        addressLine1: $addressLine1,
        addressLine2: $addressLine2
        postcode: $postcode
      }
      timezone: $timezone
    ) {
      id
      email
      firstName
      lastName
      isVerified
      commsType
      locale
      profile {
        addressLine1
        addressLine2
        postcode
      }
      timezone
    }
  }
`

export const RESEND_VERIFY_EMAIL = gql`
  mutation {
    resendVerificationEmail
  }
`

export const ASSUME_COMPANY_USER = gql`
  mutation($companyId: ID!, $admin: Boolean) {
    assumeCompanyUser(companyId: $companyId, admin: $admin) {
      token
    }
  }
`

export const UNASSUME_COMPANY_USER = gql`
  mutation($me: Boolean) {
    unassumeCompanyUser(me: $me) {
      token
    }
  }
`

export const GET_ME_2FA = gql`
  query {
    me {
      user {
        is2FAEnabled
      }
    }
  }
`

export const CREATE_AUTHENTICATOR_TOKEN = gql`
  mutation {
    createAuthenticatorToken
  }
`

export const MFA_SIGN_IN = gql`
  mutation ($mfaToken: String!, $authCode: String!) {
    mfaSignIn(mfaToken: $mfaToken, authCode: $authCode) {
      token
      targetPath
    }
  }
`

export const VERIFY_QR = gql`
 mutation ($authCode: String!) {
   verifyQR(authCode: $authCode) {
     qrVerified
     token
   }
 }
`

export const GET_QR = gql`
  query {
    qr {
      token
      imagePath
    }
  }
`
export const UPDATE_2FA_ENABLED = gql`
  mutation($is2FAEnabled: Boolean!) {
    update2FAEnabled(is2FAEnabled: $is2FAEnabled)
  }
`

export const GENERATE_RECOVERY_KEYS = gql`
  mutation {
    generateRecoveryKeys
  }
`

export const REGENERATE_RECOVERY_KEYS = gql`
  mutation {
    regenerateRecoveryKeys
  }
`

export const CREATE_RECOVERY_KEYS = gql`
  mutation($recoveryKeys: [String!]!) {
    createRecoveryKeys(recoveryKeys: $recoveryKeys)
  }
`

export const GET_RECOVERY_KEYS = gql`
  query {
    getRecoveryKeys {
      recoveryKey
      used
    }
  }
`

export const VERIFY_RECOVERY_KEY = gql`
  mutation($mfaToken: String!, $recoveryKey: String!) {
    verifyRecoveryKey(mfaToken: $mfaToken, recoveryKey: $recoveryKey) {
      usedAll
      token
      targetPath
    }
  }
`
export const SIGN_IN = gql`
  mutation($login: String!, $password: String!, $targetPath: String) {
    signIn(login: $login, password: $password, targetPath: $targetPath){
      is2FAEnabled
      token
    }
  }
`

export const SIGN_UP = gql`
  mutation($email:String!, $firstName:String!, $lastName:String!, $password:String!, $companyName:String!) {
    signUp(email:$email, firstName:$firstName, lastName:$lastName, password:$password, companyName:$companyName)
  }
`

export const FORGOTTEN_PASSWORD = gql`
  mutation($email:String!) {
    forgottenPassword(email:$email)
  }
`

export const RESET_PASSWORD = gql`
  mutation($token:String!, $password:String!) {
    resetPassword(token:$token, password:$password)
  }
`

export const SESSION_EXPIRES_AT = gql`
  query {
    sessionExpiresAt
  }
`

export const EXTEND_SESSION = gql`
  mutation {
    extendSession {
      token
    }
  }
`
export const GET_INTERCOM_ME = gql`
{
  me {
    user {
      id
      name
      firstName
      lastName
      email
      companyId
      company {
        name
        accountType
        createdAt
        activeLearnerCount
        simulationResultSentCount
        totalCreatedPolicies
        totalCustomBuiltCourses
        intercomStatus
        settings
        billingSettings {
          currency
        }
        parentCompany {
          id
          name
          parentCompany {
            id
            name
          }
        }
        intercomEnabled
        uBreachProUpgradeDate
      }
    }
  }
}
`

export const GET_SAML_AUTH_START_TOKEN = gql`
  mutation ($authType: loginAuthType!, $email: String, $companyId: ID, $origin: String, $targetPath: String) {
    getSAMLAuthStartToken(authType: $authType, email: $email, companyId: $companyId, origin: $origin, targetPath: $targetPath)
  }
`
