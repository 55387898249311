/* global localStorage */
import React from 'react'
import { Switch, generatePath } from 'react-router-dom'
import { Layout } from 'antd'
import styled from 'styled-components'
import { compose } from 'recompose'
import _get from 'lodash/get'

import Learners from './Learners'
import uBreach from './uBreach'
import {
  CreateSimulation,
  CreateSimulationFromRecommended,
  CloneSimulation,
  EditEmailTemplate,
  EditLandingPage,
  EmailTemplateBuilder,
  EmailTemplateLibrary,
  LandingPageBuilder,
  LandingPageLibrary,
  Simulations,
  UpdateSimulation
} from './uPhish'
import Courses from './Courses'
import EditCourse from './EditCourse'
import CreateCourse from './CreateCourse'
import EditCourseSlides from './EditCourseSlides'
import EditCourseSlide from './EditCourseSlide'
import LearnerCoursePreview from './LearnerCoursePreview'
import { CourseParticipation, SimulationPerformance, RiskReportPreview } from './Reports'
import { DefaultCustomerSettings, Settings, Team } from './Settings'
import AccountSettings from './AccountSettings'
import {
  CreatePolicy, EditPolicy, EditPolicyDraft,
  CreatePolicyTemplate, EditPolicyTemplate, EditPolicyTemplateDraft,
  Policies, PolicyTemplates, PolicyTemplateBuilder
} from './uPolicy'
import PaymentSettings from './PaymentSettings'
import uService from './uService'
import uServiceInvoice from './uServiceInvoice'
import MicrosoftSyncSetup from './MicrosoftSyncSetup'
import GoogleSyncSetup from './GoogleSyncSetup'
import CourseLibrary from './CourseLibrary'
import RiskReportSignUpSetup from './RiskReportSignUpSetup'
import { M365AuthTest, GoogleAuthTest } from './Debug'
import UBreachV2 from './uBreachV2/uBreach'

import routes from '../constants/routes'
import ProtectedRoute from '../components/ProtectedRoute'
import HoldingRoute from '../components/HoldingRoute'
import NavBar from '../components/Nav/NavBar'
import { SettingsSubscription } from '../components/Subscriptions'
import { connect } from '../hocs'
import { getSessionAndSettings } from '../state/selectors'
import SessionManager from '../components/SessionManager'
import { FreeTrialMessage, ExpiredAccountMessage, UserIntercom } from '../components/Main'
import uServiceInvoiceDistributor from './uServiceInvoiceDistributor'
import Dashboard from './Dashboard/Dashboard'
import SimplifiedDashboard from './Dashboard/SimplifiedDashboard'
import ULearn from './Reports/uLearn/ULearnReport'
import { adminChannel } from '../helpers/session'
import ReportCentre from './Reports/ReportCentre'
import PerformanceReport from './Reports/PerformanceReport/PerformanceReport'
import BreachReport from './Reports/BreachReport/BreachReport'
import { PhishAlertAuthM365, MessageInjectionAuthM365 } from './M365AuthComplete'
import { UBREACH_V2_ENABLED } from '../constants/environment'
import { UbreachProUpgrade } from './uBreachV2/UbreachProUpgrade'
import { useHasSessionPermission } from '../hooks'
import { permissions } from '../constants/permissions'

const { Header, Content } = Layout

export const StyledLayout = styled(Layout)`
  height: 100%;
  background: ${({ theme }) => theme.pageBG} !important;
`
export const StyledHeader = styled(Header)`
  background-color: ${({ theme }) => theme.nav};
  height: 71px;
  position: fixed;
  z-index: 900;
  width: 100%;
`
export const StyledContent = styled(Content)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 74px;
  overflow: auto;
`

const COURSE_PREVIEW = [
  routes.BUILDER_PREVIEW_COURSE,
  routes.BUILDER_PREVIEW_COURSE_LOCALE,
  routes.BUILDER_PREVIEW_SLIDE,
  routes.BUILDER_PREVIEW_SLIDE_LOCALE,
  routes.COURSE_LIBRARY_PREVIEW
]

const Main = props => {
  const { session = {}, settings = {} } = props
  const path = _get(props, 'match.path')
  const hideNav = path && COURSE_PREVIEW.includes(path)
  const { hasAllSessionPermissions } = useHasSessionPermission()

  // Redirect MSP admin users from combined uservice page (/uservice) to customers uService page (/uservice/(customers|prospects))
  // This matches their nav options
  // This only applies if prospects are enabled on their account. Otherwise they only have access to /uservice
  // Permissions check avoids a nasty loop if the MSP Admin doesn't have access to uService at all.
  let uServiceRedirect
  if (hasAllSessionPermissions([permissions.COMPANY_LIST]) && session.accountType === 'msp' && settings.enableProspects === true) {
    const uServiceTypeDefault = localStorage.getItem(`uServiceAssume|${session.companyId}|${session.userId}`)
    uServiceRedirect = generatePath(routes.USERVICE_MSP_TYPE, { accountType: uServiceTypeDefault === 'prospect' ? 'prospects' : 'customers' })
  }

  return (
    <>
      <SessionManager channel={adminChannel} />
      <SettingsSubscription />
      <UserIntercom {...{ session, settings }} />
      <StyledLayout>
        <StyledHeader style={{ display: hideNav ? 'none' : undefined }}>
          <NavBar />
        </StyledHeader>
        <StyledContent style={{ paddingTop: hideNav ? 0 : undefined }}>
          <FreeTrialMessage />
          <ExpiredAccountMessage />
          <Switch>
            <ProtectedRoute
              exact
              path={COURSE_PREVIEW}
              component={LearnerCoursePreview}
            />
            <ProtectedRoute
              exact
              path={routes.HOME}
              component={Dashboard}
              fallbackComponent={SimplifiedDashboard}
            />
            <ProtectedRoute
              exact
              path={routes.UBREACH_UPGRADE}
              component={UbreachProUpgrade}
            />
            <ProtectedRoute
              exact
              path={[routes.LEARNERS, routes.LEARNER, routes.LEARNER_OLD]}
              component={Learners}
            />
            <ProtectedRoute
              exact
              path={routes.OFFICE_365_SETUP}
              component={MicrosoftSyncSetup}
            />
            <ProtectedRoute
              exact
              path={routes.GOOGLE_SYNC_SETUP}
              component={GoogleSyncSetup}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_SIMS}
              component={Simulations}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_SIM}
              component={Simulations}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_CLONE_SIM}
              component={CloneSimulation}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_CREATE_SIM}
              component={CreateSimulation}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_CREATE_SIM_FROM_REC}
              component={CreateSimulationFromRecommended}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_EDIT_SIM}
              component={UpdateSimulation}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_AUTO_PHISH}
              redirect={decodeURIComponent(generatePath(routes.SETTINGS_SCREEN, { screenPath: 'phish/auto-phish' }))}
            />
            <ProtectedRoute
              exact
              path={[routes.UPHISH_LANDING_PAGES, routes.UPHISH_LANDING_PAGE_VIEW]}
              component={LandingPageLibrary}
            />
            <ProtectedRoute
              exact
              path={[routes.UPHISH_LANDING_PAGE_BUILDER, routes.UPHISH_LANDING_PAGE_BUILDER_VIEW]}
              component={LandingPageBuilder}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_LANDING_PAGE_CREATE}
              component={EditLandingPage}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_LANDING_PAGE_UPDATE}
              component={EditLandingPage}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_LANDING_PAGE_CLONE}
              component={EditLandingPage}
            />
            <ProtectedRoute
              exact
              path={[routes.UPHISH_EMAIL_TEMPLATES, routes.UPHISH_EMAIL_TEMPLATE_VIEW]}
              component={EmailTemplateLibrary}
            />
            <ProtectedRoute
              exact
              path={[routes.UPHISH_EMAIL_TEMPLATE_BUILDER, routes.UPHISH_EMAIL_TEMPLATE_BUILDER_VIEW]}
              component={EmailTemplateBuilder}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_EMAIL_TEMPLATE_CREATE}
              component={EditEmailTemplate}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_EMAIL_TEMPLATE_UPDATE}
              component={EditEmailTemplate}
            />
            <ProtectedRoute
              exact
              path={routes.UPHISH_EMAIL_TEMPLATE_CLONE}
              component={EditEmailTemplate}
            />
            <ProtectedRoute
              exact
              path={routes.BUILDER}
              component={Courses}
            />
            <ProtectedRoute
              exact
              path={routes.BUILDER_EDIT}
              component={EditCourse}
            />
            <ProtectedRoute
              exact
              path={routes.BUILDER_CREATE}
              component={CreateCourse}
            />
            <ProtectedRoute
              exact
              path={routes.COURSE_LIBRARY}
              component={CourseLibrary}
            />
            <ProtectedRoute
              exact
              path={[routes.BUILDER_EDIT_SLIDES, routes.BUILDER_EDIT_SLIDES_LOCALE]}
              component={EditCourseSlides}
            />
            <ProtectedRoute
              exact
              path={[routes.BUILDER_EDIT_SLIDE, routes.BUILDER_EDIT_SLIDE_LOCALE]}
              component={EditCourseSlide}
            />
            <ProtectedRoute
              exact
              path={routes.UBREACH}
              component={UBREACH_V2_ENABLED ? UBreachV2 : uBreach}
            />
            <ProtectedRoute
              exact
              path={[routes.UPOLICY, routes.UPOLICY_VIEW]}
              component={Policies}
            />
            <ProtectedRoute
              exact
              path={[routes.UPOLICY_TEMPLATES, routes.UPOLICY_TEMPLATE_VIEW]}
              component={PolicyTemplates}
            />
            <ProtectedRoute
              exact
              path={[routes.UPOLICY_TEMPLATE_BUILDER, routes.UPOLICY_TEMPLATE_BUILDER_VIEW]}
              component={PolicyTemplateBuilder}
            />
            <ProtectedRoute
              exact
              path={[routes.UPOLICY_CREATE, routes.UPOLICY_CREATE_FROM_TEMPLATE]}
              component={CreatePolicy}
            />
            <ProtectedRoute
              exact
              path={routes.UPOLICY_EDIT}
              component={EditPolicy}
            />
            <ProtectedRoute
              exact
              path={routes.UPOLICY_EDIT_DRAFT}
              component={EditPolicyDraft}
            />
            <ProtectedRoute
              exact
              path={routes.UPOLICY_TEMPLATE_CREATE}
              component={CreatePolicyTemplate}
            />
            <ProtectedRoute
              exact
              path={routes.UPOLICY_TEMPLATE_EDIT}
              component={EditPolicyTemplate}
            />
            <ProtectedRoute
              exact
              path={routes.UPOLICY_TEMPLATE_EDIT_DRAFT}
              component={EditPolicyTemplateDraft}
            />
            <ProtectedRoute
              exact
              path={routes.TEAM}
              component={Team}
            />
            <ProtectedRoute
              exact
              path={routes.COURSE_REPORT}
              component={ULearn}
            />
            <ProtectedRoute
              exact
              path={routes.LEARNER_REPORT}
              component={ULearn}
            />
            <ProtectedRoute
              exact
              path={routes.ULEARN_REPORT}
              component={ULearn}
            />
            <ProtectedRoute
              exact
              path={routes.GAP_ANALYSIS_REPORT}
              component={ULearn}
            />
            <ProtectedRoute
              exact
              path={routes.COURSE_PARTICIPATION}
              component={CourseParticipation}
            />
            <ProtectedRoute
              exact
              path={routes.SIMULATION_PERFORMANCE}
              component={SimulationPerformance}
            />
            <ProtectedRoute
              exact
              path={[routes.SETTINGS, routes.SETTINGS_SCREEN]}
              component={Settings}
            />
            <ProtectedRoute
              exact
              path={[routes.DEFAULT_CUSTOMER_SETTINGS, routes.DEFAULT_CUSTOMER_SETTINGS_SCREEN]}
              component={DefaultCustomerSettings}
            />
            <ProtectedRoute
              exact
              path={routes.ACCOUNT}
              component={AccountSettings}
            />
            <ProtectedRoute
              exact
              path={routes.PAYMENT}
              component={PaymentSettings}
            />
            <ProtectedRoute
              exact
              path={routes.USERVICE}
              component={uService}
              redirect={uServiceRedirect}
            />
            <ProtectedRoute
              exact
              path={[routes.USERVICE_MSP_TYPE, routes.USERVICE_MSP_TYPE_CREATE]}
              component={uService}
              redirect={routes.USERVICE}
            />
            <ProtectedRoute
              exact
              path={routes.USERVICE_INVOICE}
              component={uServiceInvoice}
            />
            <ProtectedRoute
              exact
              path={routes.USERVICE_INVOICE_DISTRIBUTOR}
              component={uServiceInvoiceDistributor}
            />
            <ProtectedRoute
              exact
              path={routes.RISK_REPORT_PREVIEW}
              component={RiskReportPreview}
            />
            <ProtectedRoute
              exact
              path={routes.USERVICE_RISK_REPORT_SIGNUP}
              component={RiskReportSignUpSetup}
            />
            <ProtectedRoute
              exact
              path={routes.PHISH_ALERT_M365_AUTH}
              component={PhishAlertAuthM365}
            />
            <ProtectedRoute
              exact
              path={routes.MESSAGE_INJECTION_M365_AUTH}
              component={MessageInjectionAuthM365}
            />
            <ProtectedRoute
              exact
              path={[routes.M365_AUTH_TEST_START, routes.M365_AUTH_TEST]}
              component={M365AuthTest}
            />
            <ProtectedRoute
              exact
              path={[routes.GOOGLE_AUTH_TEST_START, routes.GOOGLE_AUTH_TEST]}
              component={GoogleAuthTest}
            />
            <ProtectedRoute
              exact
              path={routes.REPORT_CENTRE}
              component={ReportCentre}
            />
            <ProtectedRoute
              exact
              path={routes.PERFORMANCE_REPORT}
              component={PerformanceReport}
            />
            <ProtectedRoute
              exact
              path={routes.BREACH_REPORT}
              component={BreachReport}
            />
            <HoldingRoute exact />
          </Switch>
        </StyledContent>
      </StyledLayout>
    </>
  )
}

export default compose(
  connect(
    getSessionAndSettings
  )
)(Main)
